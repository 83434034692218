
function FacebookWidget(){
    return(
        <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Frockysbouncers&tabs=timeline&width=300&height=400&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
        style={{
            border: "none",
            overflow: "hidden",
            frameborder: "0",
            height: "400px",
            margin: "0px auto",
            float: "none",
            display: "table"
        }}
        >
       </iframe>        
    )
}

export default FacebookWidget;